import gsap, { Observer } from 'gsap/all';
gsap.registerPlugin(Observer);

export default class CustomPointer {

  constructor(container) {
    this.pointer = document.querySelector('#roundPointer');
    this.container = container;

    this.vars = {
      duration: 0.5,
      ease: "power3"
    }

    this.setup();
  }

  setup() {

    gsap.set(this.pointer, { xPercent: -50, yPercent: -50 });
    this.toggle = gsap.timeline({ paused: true }).to(this.pointer, { duration: 0.5, autoAlpha: 1, scale: 1, ease: "none" });
    this.xTo = gsap.quickTo(this.pointer, "x", this.vars);
    this.yTo = gsap.quickTo(this.pointer, "y", this.vars);

    this.observer = Observer.create({
      target: this.container,
      type: "pointer",
      isEnabled: false,
      onMove: (self) => {
        this.xTo(self.event.clientX);
        this.yTo(self.event.clientY);
      }
    });

    this.container.addEventListener('mouseenter', this.start);
    this.container.addEventListener('mouseleave', this.stop)
  }

  start = () => {
    this.toggle.play();
    this.observer.enable();
  }

  stop = () => {
    this.toggle.reverse();
    this.observer.disable();
  }

  destroy() {
    // Remove event listeners
    this.container.removeEventListener('mouseenter', this.start);
    this.container.removeEventListener('mouseleave', this.stop);

    // Kill GSAP animations
    this.stop();
    // this.toggle.kill();
    // gsap.killTweensOf(this.pointer);

    // Disable and nullify the Observer to ensure proper garbage collection
    // this.observer.disable();
    // this.observer = null;
  }

}
