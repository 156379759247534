import { gsap, ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollToPlugin);
import ResizeManager from "./ResizeManager";
import ScrollManager from "./ScrollManager";

const px2vw = (px) => {
  let defSize = window.innerWidth <= 1024 ? 375 : 1920;
  return parseInt(px * window.innerWidth / defSize);
};

const resizeManager = new ResizeManager();
const scrollManager = new ScrollManager();

const debounce = (func, wait, immediate) => {
  let timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

const throttle = (func, wait) => {
  let timeout = null;
  let previous = 0;

  return function () {
    const now = Date.now();
    const remaining = wait - (now - previous);

    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      func.apply(this, arguments);
    } else if (!timeout) {
      timeout = setTimeout(() => {
        previous = Date.now();
        timeout = null;
        func.apply(this, arguments);
      }, remaining);
    }
  };
}

const createIntObserver = (elements, callback, settings) => {

  const observerSettings = settings ? settings : {
    root: null,
    rootMargin: `${window.innerHeight}px`,
  }

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        callback(entry.target, observer);
      }
    });
  }, observerSettings);

  if (elements instanceof HTMLCollection || elements instanceof NodeList) {
    for (const element of elements) {
      observer.observe(element);
    }
  } else {
    observer.observe(elements);
  }
}

const lazyLoad = () => {
  const lazyBg = document.querySelectorAll('[data-lazy-bg]');
  if (!lazyBg.length) return;

  if ("IntersectionObserver" in window) {
    createIntObserver(lazyBg, (target, observer) => {
      target.classList.add('loaded');
      observer.unobserve(target);
    });
  } else {
    [...lazyBg].forEach(el => el.classList.add('.loaded'));
  }
}

const toggleClass = (element, className, bool) => element?.classList.toggle(className, bool);


// Optimized getTargetTop function to defer calculations// Function to calculate the target position
const getTargetTop = (element) => {
  if (element.hash) {
    // Handle ID-based target or 'body' for '#main'
    const target = (element.hash === '#main') ? document.body : document.querySelector(element.hash);
    return target ? target.offsetTop : 0;
  } else if (element.dataset.scrollto) {
    // Handle special strings or query selector
    const scrollto = element.dataset.scrollto;
    switch (scrollto) {
      case 'bottom':
        return document.documentElement.scrollHeight - window.innerHeight;
      case 'top':
        return 0;
      default:
        const target = document.querySelector(scrollto);
        return target ? target.offsetTop : 0;
    }
  }
  return 0;
};

// Function to perform the scroll using GSAP's scrollTo plugin
const performScroll = (targetTop) => {
  gsap.to(window, { duration: 1, scrollTo: { y: targetTop, autoKill: false } });
};

// Function to handle hash on page load
const handlePageLoadWithHash = () => {
  const hash = window.location.hash;
  if (hash) {
    const target = document.querySelector(hash);
    if (target) {
      const targetTop = target.offsetTop;
      performScroll(targetTop);

      // Remove the hash from the URL without refreshing the page
      window.history.pushState("", document.title, window.location.pathname + window.location.search);
    }
  }
};

// Event listener for all clicks on the document
document.addEventListener('click', function (e) {
  // Check for scrollable link or element
  const scrollElement = e.target.closest('a[href^="#"], [data-scrollto]');
  if (scrollElement) {
    e.preventDefault();
    const targetTop = getTargetTop(scrollElement);

    // Remove the hash from the URL without refreshing the page
    window.history.pushState("", document.title, window.location.pathname + window.location.search);
    performScroll(targetTop);
  }

  // Check for 'go back' element
  if (e.target.closest('[data-goback]')) {
    e.preventDefault();
    window.history.back();
    return; // Return early since we've handled this event
  }
});

export { px2vw, debounce, throttle, lazyLoad, createIntObserver, toggleClass, performScroll, handlePageLoadWithHash, resizeManager, scrollManager }