import { gsap, ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollToPlugin);

export default function preLoader(docElement, cb = () => { }) {

  const main = docElement.querySelector("main");
  const preloader = docElement.querySelector(".preloader");
  const homeIntro = docElement.querySelector('.intro');

  let preloaderSpeed = setPreloaderSpeed();
  let introTL = homeIntro ? createIntroAnimation(homeIntro) : null;

  if (preloader) {
    animatePreloader();
  } else {
    animateLayout();
  }

  function setPreloaderSpeed() {
    const visited = localStorage.getItem('visited');

    if (!visited) {
      localStorage.setItem('visited', true);
      return 1;
    }
    // return 10;
    return 2;
  }

  function handlePageLoadWithHash() {
    const hash = window.location.hash;

    if (hash) {
      const target = document.querySelector(hash);

      if (target) {
        gsap.to(window, { scrollTo: target });
      }

      // Remove the hash from the URL without refreshing the page
      window.history.pushState("", document.title, window.location.pathname + window.location.search);
    }
  };

  function setIsLoaded() {

    handlePageLoadWithHash();
    docElement.classList.add("loaded");
    cb();
  }

  function animatePreloader() {
    const wrapper = preloader.querySelector('.preloader__inner');
    const logo = wrapper.querySelector('.preloader__logo');
    const logoPath = logo.querySelector('path');
    const pathLength = logoPath.getTotalLength();
    const textBlock = wrapper.querySelector('.preloader__info');
    const title = textBlock.querySelector('.preloader__heading');
    const subTitle = textBlock.querySelector('.preloader__subtitle');

    const hidePreloaderTL = gsap.timeline({
      paused: true,
      defaults: { ease: "expo.out" },
      onComplete() {
        preloader.remove();
        animatePreloaderTL.kill();
        hidePreloaderTL.kill();
      }
    })
      .to(preloader, { height: 0, duration: 1.5 }, "hide")
      .to(wrapper, { translateY: '50vh', duration: 1.5, autoAlpha: 0 }, "hide")
      .call(animateLayout, null, "hide+=0.5");

    const animatePreloaderTL = gsap.timeline({
      paused: true,
      defaults: { ease: "none" }
    })
      .set(logoPath, { strokeDasharray: pathLength, strokeDashoffset: pathLength, fillOpacity: 0 }, "load")
      .to(logo, { autoAlpha: 1, duration: 0.5 }, "load")
      .to(logoPath, { duration: 6, strokeDashoffset: 0 }, "load")
      .to(logoPath, { duration: 1, fillOpacity: 1 }, "load+=2")
      .to(wrapper, { y: 0, duration: 1.5, ease: "expo.out" }, "load+=3")
      .to(title, { autoAlpha: 1, y: 0, duration: 1.5, ease: "expo.out" }, "load+=3.2")
      .to(subTitle, { autoAlpha: 1, y: 0, duration: 1.5, ease: "expo.out" }, "load+=3.5")
      .add(() => hidePreloaderTL.timeScale(preloaderSpeed).play(), "load+=6");

    animatePreloaderTL.timeScale(preloaderSpeed).play();
  }

  function animateLayout() {
    const header = docElement.querySelector('header');
    const aside = docElement.querySelector('aside');

    const headerLogo = header.querySelector(".header-logo");
    const headerLinks = header.querySelectorAll(".header-menu a");
    const headerPhone = header.querySelector(".header-phone");
    const headerAddress = header.querySelector(".header-address");
    const mobileBurger = header.querySelector(".menu-toggle");
    const socials = aside.querySelectorAll("a");
    const fading = [headerLogo, mobileBurger, ...headerLinks, headerPhone, headerAddress, ...socials];

    introTL && introTL.timeScale(preloaderSpeed / 2).play();

    const layoutTL = gsap.timeline({
      delay: 0.5,
      onComplete: () => { !introTL && setIsLoaded() }
    })
      .set([header, aside], { autoAlpha: 1 })
      .to(main, { autoAlpha: 1, duration: 1, ease: "none" }, "show")
      .from(fading, { y: -50, autoAlpha: 0, stagger: 0.085 }, "show");
  }

  function createIntroAnimation(section) {
    const heroImg = section.querySelectorAll('.section-img--mobile, .section-img--desktop');
    const smallImg = section.querySelector('.section-bottom__img');
    const playBtn = section.querySelector('.section-button');
    const title = [...section.querySelectorAll('.section-title span')];
    const smallText = section.querySelector('.section-bottom__info-text');
    const contactBtn = section.querySelector('.section-bottom__info-button');

    return gsap.timeline({
      paused: true,
      defaults: {
        ease: "power2.out",
        // ease: "none",
        overwrite: true
      },
      onComplete: setIsLoaded
    })
      .to(playBtn, { y: 0, autoAlpha: 1, duration: 1 }, "show")
      .to(title[0], { y: 0, autoAlpha: 1, duration: 1.25 }, "show+=0.5")
      .to(title[1], { y: 0, autoAlpha: 1, duration: 1 }, "show+=0.75")
      .to(title[2], { y: 0, autoAlpha: 1, duration: 0.75 }, "show+=1")
      .to(smallImg, { y: 0, autoAlpha: 1, duration: 1 }, "show+=1")
      .to(smallText, { y: 0, autoAlpha: 1, duration: 1.25 }, "show+=1")
      .to(contactBtn, { y: 0, autoAlpha: 1, duration: 1.25 }, "show+=1.25")
      .to(heroImg, { y: 0, autoAlpha: 1, duration: 1.5, ease: "sine.out" }, "show+=1.5");

  }

}