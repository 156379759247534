import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
import Swiper from "swiper";
import { Autoplay, Scrollbar, Mousewheel, FreeMode, EffectCreative, Navigation } from 'swiper/modules';

// function createServiceSlider(wrapper) {

//   return new Swiper(wrapper, {
//     modules: [EffectCreative, Autoplay],
//     direction: "vertical",
//     slidesPerView: 1,
//     allowTouchMove: false,
//     oneWayMovement: true,
//     speed: 1000,

//     effect: "creative",
//     // loop: true,
//     autoplay: {
//       delay: 1500,
//     },
//     creativeEffect: {
//       prev: {
//         opacity: 0,
//         translate: [0, "-100%", -1000],
//         // translate: [0, "-50%", -200],
//       },
//       next: {
//         opacity: 0,
//         translate: [0, "50%", 0],
//       },
//     },
//     on: {
//       init: (swiper) => {
//         swiper.autoplay.stop();
//       }
//     }
//   });
// }

function createServiceSlider(wrapper) {
  let swiperOptions = {
    modules: [Autoplay],
    direction: "horizontal",
    slidesPerView: 1,
    allowTouchMove: true,
    // oneWayMovement: true,
    speed: 1000,
    loop: true,
    autoplay: {
      delay: 1500,
    },
    on: {
      init: (swiper) => {
        swiper.autoplay.stop();
      }
    }
  };

  if (window.innerWidth > 1024) {
    swiperOptions.modules.push(EffectCreative);
    swiperOptions.direction = "vertical";
    swiperOptions.allowTouchMove = false;
    swiperOptions.oneWayMovement = true;
    swiperOptions.effect = "creative";
    swiperOptions.creativeEffect = {
      prev: {
        opacity: 0,
        translate: [0, "-100%", -1000],
      },
      next: {
        opacity: 0,
        translate: [0, "50%", 0],
      },
    };
  }

  return new Swiper(wrapper, swiperOptions);
}


function createTeamSlider(wrapper, direction, next = null, prev = null) {
  return new Swiper(wrapper, {
    modules: [Mousewheel, FreeMode, Navigation],
    direction: direction, // "horizontal" or "vertical"
    slidesPerView: 'auto',
    grabCursor: true,
    navigation: {
      nextEl: next,
      prevEl: prev,
    },
    mousewheel: {
      invert: false,
      forceToAxis: true
    },
    freeMode: {
      enabled: true,
      sticky: true,
    }
  });
}

function partnersSlider(section) {
  const sliderWrapper = section.querySelector('.swiper');
  const initialSpeed = 5000;
  const manualSpeed = 600;

  const slider = new Swiper(sliderWrapper, {
    modules: [Autoplay, Mousewheel, FreeMode],
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 100,
    grabCursor: true,
    speed: initialSpeed,
    mousewheel: {
      forceToAxis: true,
    },
    freeMode: {
      enabled: true,
    },
    autoplay: {
      delay: 0,
      disableOnInteraction: true,
    },
    on: {
      init: (swiper) => {
        swiper.disable();

        ScrollTrigger.create({
          trigger: section,
          // markers: true,
          onToggle: (self) => {
            if (self.isActive) {
              swiper.enable();
              swiper.params.speed = initialSpeed;
              swiper.autoplay.start();
            } else {
              swiper.disable;
            }
          }
        })
      }
    }
  });

}

function testimonialsSlider(section) {
  const sliderWrapper = section.querySelector('.swiper');
  const scrollbar = section.querySelector('.swiper-scrollbar');

  const slider = new Swiper(sliderWrapper, {
    modules: [Scrollbar, Mousewheel, FreeMode],
    loop: true,
    slidesPerView: 'auto',
    grabCursor: true,
    mousewheel: {
      forceToAxis: true
    },
    freeMode: {
      enabled: true,
    },
    scrollbar: {
      el: scrollbar,
      draggable: true
    },

  })
}

export { createServiceSlider, createTeamSlider, partnersSlider, testimonialsSlider }