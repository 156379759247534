import { lock, unlock } from 'tua-body-scroll-lock';
import Lenis from '@studio-freight/lenis';
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
import { ScrollBar } from "./ScrollBar";

export default class ScrollManager {
  constructor() {

    this.body = document.querySelector('body');
    this.wrapper = document.querySelector('main');
    this.smoothScroll = null;
    this.scrollBar = null;

    this.init();
  }

  init() {
    gsap.matchMedia().add("(min-width: 1025px)", () => {
      this.initScrollBar();
      this.initSmoothScroll();

      return this.cleanUp;
    });
  }

  initScrollBar() {
    this.scrollBar = ScrollBar.create({
      scrollbarClass: 'body-scroll',
    });
  }

  initSmoothScroll() {
    this.smoothScroll = new Lenis({
      smoothWheel: true,
      wheelMultiplier: 1,
      smoothTouch: false,
      normalizeWheel: true,
      touchMultiplier: 2
    });

    gsap.ticker.add(this.smoothScrollRAF);
  }

  smoothScrollRAF = (time) => {
    if (this.smoothScroll) {
      this.smoothScroll.raf(time * 1000);
      gsap.ticker.lagSmoothing(0);
    }
  }

  cleanUp = () => {
    gsap.ticker.remove(this.smoothScrollRAF);

    if (this.smoothScroll) {
      this.smoothScroll.destroy();
      this.smoothScroll = null;
    }

    if (this.scrollBar) {
      this.scrollBar.stop();
      this.scrollBar = null;
    }
  }

  disableScroll() {
    if (!this.isScrollDisabled) {
      lock();
      this.smoothScroll?.stop();
      this.scrollBar?.stop();

      this.isScrollDisabled = true;
    }
  }

  enableScroll() {
    if (this.isScrollDisabled) {
      unlock();
      this.smoothScroll?.start();
      this.scrollBar?.start();

      this.isScrollDisabled = false;
    }
  }
}