import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import { toggleClass } from "../utils/index";

import preLoader from "./animations-preloader";
import superMenu from "../components/supermenu";
import videoPopup from "../components/video-popup";
import contactForm from "../components/contact-form";
import gmap from "../components/gmap";

// DOM elements shared across functions
const html = document.querySelector('html');
const body = html.querySelector('body');
const header = body.querySelector('header');
const main = body.querySelector('main');
const aside = body.querySelector('aside');
const footer = body.querySelector('footer');
const sections = [...main.querySelectorAll("section")];
const lastSection = (sections.length > 1) ? sections[sections.length - 1] : (sections[0] ?? main);
const firstSection = (sections.length > 1) ? sections[0] : (sections[0] ?? main);


// Elements, animated on scroll
// const darkSections = sections.filter(section => section.dataset.theme === "dark");
// const lightSections = sections.filter(section => section.dataset.theme === "light");

const fades = [...body.querySelectorAll('[data-fade]')];
const parallaxes = [...body.querySelectorAll('[data-parallax]')];
const staggers = [...body.querySelectorAll('[data-stagger]')];
const pinned = [...body.querySelectorAll('[data-pin]')];



export default function animationsController(cb = () => { }) {

  preLoader(html, () => {
    initializeAnimations();
    cb();
  });

  // Combined function for all animations and ScrollTriggers
  function initializeAnimations() {

    // Top menu navigation
    superMenu(header);

    // OnScroll Animations
    onScrollAnimations();

    // popups
    videoPopup();
    contactForm();

    // Google map
    gmap();

  }

  function onScrollAnimations() {

    const createScrollTrigger = (triggerElement, start, end, toggleClassTarget, toggleClassStr, markers = false) => {
      ScrollTrigger.create({
        markers: markers,
        trigger: triggerElement,
        start: start,
        end: end,
        onToggle: (e) => toggleClass(toggleClassTarget, toggleClassStr, e.isActive),
      });
    };

    const animateFades = (el) => {

      let type = el.dataset.fade;

      let tl = gsap.timeline({
        // defaults: {
        //   // ease: "power2.out"
        //   // ease: "none"
        // },
        scrollTrigger: {
          // markers: true,
          trigger: el,
          toggleActions: "play none none reverse",
        }
      });

      switch (type) {
        case 'in':
          tl.to(el, { duration: 1, autoAlpha: 1.5, ease: "none" });
          break;
        case 'up':
          tl.to(el, { duration: 1.5, y: 0, autoAlpha: 1, ease: "power2.out" });
          break;
        default:
          break;
      }
    }

    const animateParallaxes = (el) => {
      let type = el.dataset.parallax;

      const triggerIn = el.closest('section');
      const triggerOut = triggerIn?.nextElementSibling;

      let tlOut = gsap.timeline({
        scrollTrigger: {
          trigger: triggerOut,
          scrub: true,
        }
      });

      switch (type) {
        case 'down':
          tlOut.to(el, { yPercent: 100, autoAlpha: 1 });
          break;
        case 'up':
          tlOut.to(el, { yPercent: -100, autoAlpha: 0 });
          break;
        default:
          break;
      }
    }

    const animateStaggers = (el) => {

      let items = [...el.querySelectorAll('[data-item]')];
      if (!items.length) return;

      let type = el.dataset.stagger;
      let tl = gsap.timeline({
        scrollTrigger: {
          // markers: true,
          trigger: el,
          toggleActions: "play none none reverse",
        }
      });

      switch (type) {
        case 'fadein':
          tl.from([items], { autoAlpha: 0, ease: "none", stagger: 0.15 });
          break;
        case 'fadeup':
          tl.from([items], { autoAlpha: 0, yPercent: 50, ease: "none", stagger: 0.15 });
          break;
        default:
          tl.from([items], { autoAlpha: 0, ease: "none", stagger: 0.15 });
          break;
      }
    }

    // const animateParallax = (section) => {
    //   const items = section.querySelectorAll('[data-parallax-item]');
    //   const animate = gsap.timeline({
    //     scrollTrigger: {
    //       trigger: section,
    //       pin: true,
    //       pinSpacing: false,
    //       scrub: true
    //     }
    //   }).to(section, { y: () => `-=${section.clientHeight / 2}px` }, "parallax");

    //   items.length && items.forEach(item => {
    //     const offset = item.dataset.parallaxItem;
    //     animate.to(item, { y: () => `-=${window.innerHeight * offset}` }, "parallax");
    //   });
    // }

    const toggleHeader = () => {

      gsap.timeline({
        scrollTrigger: {
          scrub: true,
          start: "top top",
          end: () => `+=${window.innerHeight}px`,
        }
      })
        .to([header, aside], { yPercent: -100, autoAlpha: 0 }, "fadeout")
    }

    const toggleColors = () => {
      const bodyBg = body.querySelector('.body-bg');
      const togglers = [...sections, footer];

      togglers.forEach((section, index) => {
        // Extract the start color from the current section's data-color attribute
        const startColorAttr = section.getAttribute('data-color');
        const [startR, startG, startB] = startColorAttr.split(',').map(Number);

        // Determine the target color from the next section's data-target-color attribute
        let targetR, targetG, targetB;
        if (index < togglers.length - 1) {
          const nextSection = togglers[index + 1];
          const targetColorAttr = nextSection.getAttribute('data-color');
          [targetR, targetG, targetB] = targetColorAttr.split(',').map(Number);
        } else {
          // For the last section, keeping the same color
          [targetR, targetG, targetB] = [startR, startG, startB];
        }

        gsap.to({}, {
          scrollTrigger: {
            trigger: section,
            scrub: true,
            // markers: true,
            start: "top bottom",
            end: "bottom top",
            onUpdate: self => {
              // Calculate and round intermediate color based on scroll progress
              const r = Math.round(startR + (targetR - startR) * self.progress);
              const g = Math.round(startG + (targetG - startG) * self.progress);
              const b = Math.round(startB + (targetB - startB) * self.progress);

              // Apply the calculated color to the body background
              bodyBg.style.backgroundColor = `rgb(${r}, ${g}, ${b})`;
            }
          }
        });
      });
    };

    toggleColors();

    fades.length && fades.forEach(animateFades);
    staggers.length && staggers.forEach(animateStaggers);
    parallaxes.length && parallaxes.forEach(animateParallaxes);



    gsap.matchMedia().add("(min-width: 768px)", () => {
      toggleHeader();
    });

  }
}

