import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
import { scrollManager } from ".";

export default class PopupManager {
  constructor(popup, openTriggers = [], closeTriggers = [], onOpen = () => { }, onClose = () => { }) {
    this.popup = popup;
    this.openTriggers = this._nodesToArray(openTriggers);
    this.closeTriggers = this._nodesToArray(closeTriggers);
    this.onOpen = onOpen;
    this.onClose = onClose;

    this.togglePopup = gsap.timeline({ paused: true })
      .to(this.popup, { autoAlpha: 1, duration: 0.5 });

    this.attachTriggerEvents();
  }

  static create(popup, openTriggers, closeTriggers, onOpen, onClose) {
    return new PopupManager(popup, openTriggers, closeTriggers, onOpen, onClose);
  }

  open(...args) {
    this.togglePopup.play();
    this.popup.classList.add('active');
    this.attachPopupEvents();
    scrollManager.disableScroll();
    this.onOpen(...args);
  }

  close(...args) {
    this.togglePopup.reverse();
    this.popup.classList.remove('active');
    this.detachPopupEvents();
    scrollManager.enableScroll();
    this.onClose(...args);
  }

  attachTriggerEvents() {
    this.openTriggers.forEach(trigger => trigger.addEventListener('click', () => this.open()));
    this.closeTriggers.forEach(trigger => trigger.addEventListener('click', () => this.close()));
  }

  attachPopupEvents() {
    document.addEventListener('keydown', this.handleEscape.bind(this));
    window.addEventListener('click', this.handleClickOutside.bind(this));
  }

  detachPopupEvents() {
    document.removeEventListener('keydown', this.handleEscape.bind(this));
    window.removeEventListener('click', this.handleClickOutside.bind(this));
  }

  handleEscape(e) {
    if (e.key === "Escape") this.close();
  }

  handleClickOutside(e) {
    if (e.target === this.popup) this.close();
  }

  _nodesToArray(elements) {
    if (NodeList.prototype.isPrototypeOf(elements) || HTMLCollection.prototype.isPrototypeOf(elements)) {
      return Array.from(elements);
    }
    return Array.isArray(elements) ? elements : [elements];
  }
}
