import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
import { createServiceSlider, createTeamSlider, partnersSlider, testimonialsSlider } from "./animations-sliders";
import SlideManager from "../utils/SlidesManager";
import CustomPointer from "../utils/CustomPointer";

// Check if the screen height is greater than the width
const isPortrait = window.innerHeight > window.innerWidth;

export default function homeAnimations() {

  const home = document.querySelector('.home');
  if (!home) return;

  // const intro = home.querySelector('.intro');
  const services = home.querySelector('.services');
  const team = home.querySelector('.team');
  const partners = home.querySelector('.partners');
  const testimonials = home.querySelector('.testimonials');

  services && animateServices(services);
  team && animateTeam(team);
  partners && partnersSlider(partners);
  testimonials && testimonialsSlider(testimonials);


  function animateServices(section) {
    const sliderLwrapper = section.querySelector('.section-slider--large .swiper');
    const sliderSwrapper = section.querySelector('.section-slider--small .swiper');

    const navs = section.querySelectorAll('[data-slide-toggle]');
    const sliderL = createServiceSlider(sliderLwrapper);
    const sliderS = createServiceSlider(sliderSwrapper);
    let isSyncing = false;

    if (!sliderL || !sliderS) return;

    const autoplayDelay = (sliderL.params.autoplay.delay + sliderL.params.speed) / 1000;

    const toggleNavs = (activeIdx) => {
      navs.forEach((nav, idx) => {
        const track = nav.querySelector('.item-line__track');
        nav.classList.toggle('active', idx === activeIdx);
        if (idx === activeIdx) {
          gsap.to(track, { scaleX: 1, duration: autoplayDelay });
        } else {
          gsap.set(track, { scaleX: 0, delay: 0.2 });
        }
      });
    }

    sliderL.on('slideChange', () => {
      if (!isSyncing) {
        isSyncing = true;
        toggleNavs(sliderL.realIndex);
        sliderS.slideTo(sliderL.realIndex);
        isSyncing = false;
      }
    });

    sliderS.on('slideChange', () => {
      if (!isSyncing) {
        isSyncing = true;
        toggleNavs(sliderS.realIndex);
        sliderL.slideTo(sliderS.realIndex);
        isSyncing = false;
      }
    });

    if (navs.length > 0) {
      navs.forEach((nav, idx) => {
        nav.addEventListener('click', () => {
          sliderL.slideTo(idx);
          sliderS.slideTo(idx);
          toggleNavs(idx);
        });
      });
    }

    ScrollTrigger.create({
      // markers: true,
      trigger: section,
      start: 'top center',
      onToggle: self => {
        if (self.isActive) {
          sliderL?.autoplay?.start();
          sliderS?.autoplay?.start();
        } else {
          sliderL?.autoplay?.stop();
          sliderS?.autoplay?.stop();
        }
      }
    });
  }

  function animateTeam(section) {

    const container = section.querySelector('.container');

    // Top gallery
    const topGallery = section.querySelector('.section-gallery');
    const topGallerySlides = topGallery.querySelectorAll('.top-gallery');
    const topContent = section.querySelector('.section-content');

    // Main horizontal slider
    const sliderXwrapper = section.querySelector('.slider-x');
    const wrapper = sliderXwrapper.querySelector('.swiper-wrapper');
    const slidesX = wrapper.querySelectorAll('.slide-x');
    const controls = section.querySelector('.slider-controls');

    const nextButton = controls.querySelector('.next-slide');
    const prevButton = controls.querySelector('.prev-slide');

    const sliderX = createTeamSlider(sliderXwrapper, 'horizontal', nextButton, prevButton);
    let customPointer;

    const managerXslider = new SlideManager(wrapper, slidesX, 'x');
    managerXslider.collapseSlides();

    // Vertical sliders
    let slidersY = [];
    let managerYsliders = [];
    const sliderYwrappers = [...sliderXwrapper.querySelectorAll('.slider-y')];

    sliderYwrappers.forEach((wrapper, idx) => {
      const container = wrapper.querySelector('.swiper-wrapper');
      const slides = container.querySelectorAll('.slide-y');

      slidersY[idx] = createTeamSlider(wrapper, 'vertical');

      const collapseReveal = () => {
        managerYsliders[idx] = new SlideManager(container, slides, 'y', 'start');
        managerYsliders[idx].collapseSlides();

        const handleMouseEnter = () => {
          managerYsliders[idx].revealSlides();
        };

        const handleMouseLeave = () => {
          slidersY[idx].slideTo(0, 600);
          managerYsliders[idx].collapseSlides();
        };

        // Pointer events
        wrapper.addEventListener('mouseenter', handleMouseEnter);
        wrapper.addEventListener('mouseleave', handleMouseLeave);
      }

      gsap.matchMedia().add("(min-width: 1024px)", collapseReveal);
    });


    // Collapse top gallery & move more to center by x
    gsap.timeline({
      scrollTrigger: {
        // markers: true,
        trigger: section,
        start: 'top 20%',
        end: '30% top',
        scrub: true,
      }
    })
      .to(topGallery, { x: 0, }, "team")
      .to(topGallerySlides, { rotate: 0, x: 0, y: 0 }, "team")

    // Translate container to its original position, downscale galleries & show bottom slider
    gsap.timeline({
      scrollTrigger: {
        // markers: true,
        trigger: section,
        start: '30% top',
        end: '60% top',
        scrub: true,
      }
    })
      .to(container, { y: 0 }, "team2")
      .to(topGallery, { scale: 1 }, "team2")
      .to(topContent, {
        autoAlpha: 0,
        y: () => !isPortrait ? '-30vh' : '0',
        x: () => !isPortrait ? '0' : '-30vw'
      }, "team2");

    // Collapse / reveal X slides
    ScrollTrigger.create({
      trigger: section,
      start: '60% top',
      onEnter: () => {
        topGallery.classList.add('top-hidden');
        sliderXwrapper.classList.add('visible');

        managerXslider.revealSlides(() => {
          controls.classList.add('active');
          sliderX.slideTo(2, 600);
          setTimeout(() => {
            sliderXwrapper.classList.add('active');
            customPointer = new CustomPointer(sliderXwrapper);
          }, 600);
        });
      },
      onLeaveBack: () => {
        sliderX.slideTo(0, 600);
        managerXslider.collapseSlides();
        controls.classList.remove('active');
        sliderXwrapper.classList.remove('visible');
        sliderXwrapper.classList.remove('active');
        topGallery.classList.remove('top-hidden');
        customPointer && customPointer.destroy();
        customPointer = null;
      }
    })

    // Pin team section
    ScrollTrigger.create({
      trigger: section,
      start: 'top top',
      end: () => `+=${team.clientHeight * 1.2}`,
      pin: true
    });
  }

}