import { Loader } from '@googlemaps/js-api-loader';

export default function gmap() {
  const container = document.querySelector('.footer-map');
  if (!container) return;

  const { key, lng, lat } = container.dataset;

  const loader = new Loader({
    apiKey: key,
    version: "weekly",
    libraries: ["places"]
  });

  const mapOptions = {
    center: {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    },
    zoom: 14,
    disableDefaultUI: true,
  };

  loader.importLibrary('maps').then(() => {
    const map = new google.maps.Map(container, mapOptions);
    const position = new google.maps.LatLng(mapOptions.center.lat, mapOptions.center.lng);

    new google.maps.Marker({
      position,
      map
    });
  });
}
