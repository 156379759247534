import { scrollManager } from "../utils";

export default function superMenu(header) {
  const menuToggle = header.querySelector('[data-menu-toggle]');
  if (!menuToggle) return;

  const mobileMenu = header.querySelector('.mobile-menu');
  const menuLinks = mobileMenu.querySelectorAll('a');

  menuToggle.addEventListener('click', () => {

    if (header.classList.contains('menu-opened')) {
      closeMenu();
    } else {
      openMenu();
    }
  });

  // Add event listeners to each menu link
  menuLinks.forEach(link => {
    // Remove the hash from the URL without refreshing the page
    window.history.pushState("", document.title, window.location.pathname + window.location.search);
    link.addEventListener('click', closeMenu);
  });


  function openMenu() {
    scrollManager.disableScroll();
    header.classList.add('menu-opened');
  }

  function closeMenu() {
    scrollManager.enableScroll();
    header.classList.remove('menu-opened');
  }
}