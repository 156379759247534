import PopupManager from "../utils/PopupManager";

export default function videoPopup() {

  const popup = document.querySelector('[data-modal-video]');
  const video = popup?.querySelector('video');
  if (!popup || !video) return;

  const popupTriggers = [...document.querySelectorAll('[data-modal-video-open]')];

  const popupManager = PopupManager.create(popup, popupTriggers, [], () => {
    video?.play();
  }, () => {
    video?.pause();
  });
}