import PopupManager from "../utils/PopupManager";

export default function contactForm() {

  const contactModal = document.querySelector('[data-modal-contact]');
  if (!contactModal) return;

  const openContacts = document.querySelectorAll('[data-modal-contact-open]');
  const closeContact = contactModal.querySelector('[data-modal-close]');

  const formsWrappers = [...contactModal.querySelectorAll('.form-body')];
  const forms = [...contactModal.querySelectorAll('form')];
  const toggles = [...contactModal.querySelectorAll('.form-select button')];

  const submitButton = contactModal.querySelector('.form-submit button');

  const infoPopup = document.querySelector('.modal-info');
  const infoPopupClose = infoPopup.querySelector('.popup-close');

  let activeForm = 0;

  const formPopupManager = PopupManager.create(
    contactModal, openContacts, [closeContact]
  );

  const infoPopupManager = PopupManager.create(
    infoPopup, [], [infoPopupClose]
  )


  const toggleActive = (activeIdx) => {

    activeForm = activeIdx;

    formsWrappers.forEach((f, formIdx) => {
      f.classList.toggle('active', formIdx === activeIdx);
    });

    toggles.forEach((b, bIdx) => {
      b.classList.toggle('active', bIdx === activeIdx);
    });
  }

  toggles.forEach((b, idx) => {
    b.addEventListener('click', () => toggleActive(idx));
  });

  submitButton.addEventListener('click', (e) => {
    e.preventDefault();
    submitForm(forms[activeForm]);
  })



  const getInputs = (form) => {
    return [...form.querySelectorAll('input, textarea')]
      .reduce((visibleInputs, input) => {
        if (input.type !== 'hidden') {
          visibleInputs.push(input);
        }
        return visibleInputs;
      }, []);
  }

  function showPopup(message, result = 'error') {

    const span = infoPopup.querySelector('span');
    const icons = [...infoPopup.querySelectorAll('.icon')];
    span.innerHTML = message;

    icons.forEach(i => i.classList.toggle('active', i.classList.contains(`icon-${result}`)));

    infoPopupManager.open();
  }

  async function submitForm(form) {

    const data = new FormData(form);
    const visibleInputs = getInputs(form);
    const method = form.method;
    const url = `${BAMBOO.baseUrl}/wp-json/contact-form-7/v1/contact-forms/${form.id}/feedback`;

    const onSuccess = (message) => {
      visibleInputs.length && visibleInputs.forEach((input) => {
        const label = input.closest("label");

        if (label) {
          label.classList.remove("error");
        }

        if (input.type !== "checkbox") {
          input.value = "";
        } else {
          input.checked = false;
        }
      });

      formPopupManager.close();
      showPopup(message, 'success');
    };

    const onValidateError = (errors) => {
      errors.forEach((error) => {
        const input = visibleInputs.find((i) => i.name === error.field);

        if(!input) {
          return;
        }

        const label = input.closest("label");

        input.oninput = () => {
          label.classList.remove("error");
        }

        if (label) {
          label.classList.add("error");
          label.querySelector(".tip").innerHTML = error.message;
        } else {
          console.log(error);
        }
      });
    };

    const onServerError = (error) => {
      formPopupManager.close();
      showPopup(error, 'error');
    };

    try {
      const response = await fetch(url, { method, credentials: "same-origin", body: data });
      const responseData = await response.json();

      switch (responseData.status) {
        case "validation_failed":
          onValidateError(responseData.invalid_fields);
          break;
        case "mail_sent":
          onSuccess(responseData.message);
          break;
        case "mail_error":
          onServerError(responseData);
          break;
        default:
          console.log(responseData.status);
      }
    } catch (err) {
      onServerError(err);
    }
  }
}