import gsap from "gsap/gsap-core";

export default class SlideManager {
  constructor(wrapper, slides, axis = 'x', referencePoint = 'center') {
    this.wrapper = wrapper;
    this.slides = slides;
    this.axis = axis; // 'x' or 'y'
    this.referencePoint = referencePoint; // 'center' or 'start'
    this.slidePositionsPercent = [];
    this.calculatePositions(); // Initialize positions
  }

  calculatePositions() {
    const wrapperSize = this.axis === 'x' ? this.wrapper.clientWidth : this.wrapper.clientHeight;
    this.slidePositionsPercent = []; // Reset positions

    this.slides.forEach((slide, index) => {
      const slideOffset = this.axis === 'x' ? slide.offsetLeft : slide.offsetTop;
      const slideOffsetPercent = (slideOffset / wrapperSize) * 100;
      const slideSizePercent = ((this.axis === 'x' ? slide.clientWidth : slide.clientHeight) / wrapperSize) * 100;
      let translatePercent;

      if (this.referencePoint === 'center') {
        translatePercent = 50 - slideOffsetPercent - (slideSizePercent / 2);
      } else { // 'start'
        translatePercent = 0 - slideOffsetPercent;
      }

      this.slidePositionsPercent[index] = translatePercent;
    });
  }

  collapseSlides(callback) {
    const onComplete = () => {
      if (typeof callback === 'function') {
        callback();
      }
    };

    this.slides.forEach((slide, index) => {
      const animProps = {
        duration: 0.6,
        scale: (index === 0) ? 1 : 0.8,
        autoAlpha: (index === 0) ? 1 : 0,
        onComplete: index === this.slides.length - 1 ? onComplete : null // Call only after the last slide animation
      };
      animProps[this.axis === 'x' ? 'xPercent' : 'yPercent'] = this.slidePositionsPercent[index];

      gsap.to(slide, animProps);
    });
  }

  revealSlides(callback) {
    const onComplete = () => {
      if (typeof callback === 'function') {
        callback();
      }
    };

    this.slides.forEach((slide, index) => {
      const animProps = {
        duration: 0.6,
        scale: 1,
        autoAlpha: 1,
        onComplete: index === this.slides.length - 1 ? onComplete : null // Call only after the last slide animation
      };
      animProps[this.axis === 'x' ? 'xPercent' : 'yPercent'] = 0;

      gsap.to(slide, animProps);
    });
  }
}
